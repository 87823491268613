@mixin generate-color-variables($prefix: '', $color-map) {

    @each $key, $value in $color-map {
        @if type-of($value) == map {
            @include generate-color-variables(#{$prefix}-#{$key}, $value);
        }

        @else {
            --aria-#{$prefix}-color-#{$key}: #{$value};
        }
    }
}

@mixin apply-color($color-map, $property, $element: null) {

    @each $class, $color in $color-map {
        @if $element {
            &.#{$class} #{$element} {
                #{$property}: $color;
            }
        }

        @else {
            &.#{$class} {
                #{$property}: $color;
            }
        }

    }
}

$enable-grid-classes: true;
$enable-cssgrid: false;

@import "../node_modules/bootstrap/scss/bootstrap-grid";
@import 'theme';

html, body { height: 100%; }
body {
  margin: 0;
  background-color: var(--aria-background-color-2);
  overflow-x: hidden;
}
.aria-flex-spacer {
  flex: 1 auto;
}
.aria-flex {
  display: flex;
}

.align-center {
  align-content: center;
}

.align-items-center {
  align-items: center;
}

.justify-start {
  justify-content: start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.aria-flex-column {
  display: flex;
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.gap-10 {
  gap: 10px;
}

.gap-0 {
  gap: 0px;
}

.aria-title-2 {
  color: var(--aria-misc-color-green);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.aria-panel-title {
  color: var(--aria-accent-color-900);
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal;
  margin-bottom: 12px;
  line-height: 24px !important;
}

.aria-title {
  color: var(--aria-misc-color-green);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.aria-headline {
  color: var(--aria-misc-color-green);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
}

.v-align-middle {
  vertical-align: middle;
}

.h-100 {
  height: 100%!important;
}

.h-auto {
  height: auto!important;
}

.w-100 {
  width: 100%!important;
}

.w-auto {
  width: auto!important;
}

[hidden] {
  display: none !important;
}

.mat-datepicker-toggle, .mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button {
  color: var(--aria-system-color-primary);
}

.mat-calendar-arrow {
  color: var(--aria-system-color-primary);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 10px 10px white inset !important;
}


.mat-mdc-table tbody {
  color: var(--aria-black-color-800);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  .rowSelected {
    background-color: var(--aria-black-color-100) !important;
  }
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  border-bottom-color: #CEDAE3; //var(--aria-black-color-50); FIXME-diseño del figma
  color: var(--aria-accent-color-900);
  text-overflow: clip!important;
}

.mat-mdc-table thead .mat-mdc-header-row .mat-mdc-header-cell {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 500;
  color: var(--aria-primary-color-900) !important;
  background-color: var(--aria-primary-color-50);
}

//BUTTON GREEN
.aria-button-green-light-1 {
  background-color: var(--aria-system-color-primary)!important;
  font-family: 'Barlow'!important;
  font-style: normal!important;
  font-weight: 500!important;
  font-size: 14px!important;
  line-height: 17px!important;
  color: var(--aria-background-color-1)!important;
  border-radius: 5px;
  padding: 8px 10px!important;
  text-align: center!important;
  border: solid 1px var(--aria-system-color-primary);
}

.aria-button-green-light-1:disabled {
  background: linear-gradient(0deg, rgba(254, 252, 252, 0.6), rgba(254, 252, 252, 0.6)), var(--aria-semantic-color-green-light-4);
  border: var(--aria-semantic-color-green-light-5) solid 1px;
}

.aria-button-small-green-light-1 {
  background-color: var(--aria-system-color-primary)!important;
  font-size: 12px!important;
  font-family: 'Barlow'!important;
  font-weight: 500!important;
  font-style: normal!important;
  color: var(--aria-background-color-1)!important;
  border-radius: 3px;
  padding: 0px 10px!important;
  text-align: center!important;
  border: solid 1px var(--aria-system-color-primary);
}

.aria-button-green-outlined-1 {
  background-color: var(--aria-background-color-1)!important;
  border: var(--aria-system-color-primary) 1px solid!important;
  border-radius: 5px!important;
  padding: 8px 12px!important;
  color: var(--aria-system-color-primary)!important;
  font-family: 'Barlow'!important;
  font-style: normal!important;
  font-weight: 500!important;
  font-size: 14px!important;
  line-height: 17px!important;
}

//BUTTON GRAY
.aria-button-gray-1 {
  background-color: var(--aria-black-color-100)!important;
  border-radius: 5px!important;
  border: none!important;
}

.swal2-container.swal2-bottom>.swal2-popup {
  box-shadow: none!important;
}

//TABLE
.aria-table-content {
  background-color: var(--aria-background-color-1)!important;
  margin: 0px;
}

.aria-cell-with-icon {
  display: flex;
  align-items: center;
}

//DASHBOARD
.chart-tooltip {
  background-color: var(--aria-black-color-800)!important;
  padding: 3px 5px;
  border-radius: 5px;
  color: var(--aria-background-color-1)!important;
  width: fit-content!important;
  font-family: 'Barlow';
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  z-index: 999;

  p {
      color: var(--aria-background-color-1)!important;
      font-family: 'Barlow';
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
  }
}

.chart-tooltip-circle {
  height: 5px;
  width: 5px;
  display: inline-block;
  border-radius: 50%;
}

.chart-legend {
  .row {
      margin-bottom: 12px;
  }
}

.chart-legend-title {
  color: var(--aria-misc-color-blue)!important;
  font-family: 'Barlow'!important;
  font-size: 14px!important;
  font-style: normal!important;
  font-weight: 500!important;
  line-height: normal!important;
  margin: 0px!important;
}

.chart-legend-rect {
  height: 14px;
  width: 14px;
  display: inline-block;
  border-radius: 2px;
}

.chart-legend-item {
  color: var(--aria-black-color-600);
  font-family: 'Barlow';
  font-size: 13.5px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

//SWEET-ALET
.swal2-container.swal2-bottom>.swal2-popup{
  box-shadow: none!important;
}

.snackbar-info {
  border-radius: 8px;
  border: var(--aria-toastr-color-blue-1) 1px solid!important;
  .mat-mdc-snack-bar-container, .mdc-snackbar__surface, .mdc-snackbar__label, .mdc-button__label, a  {
    background-color: var(--aria-toastr-color-blue-2)!important;
    color: var(--aria-toastr-color-blue-1)!important;
    border-radius: 8px!important;
  }
  .toast-title .toast-text {
    color: var(--aria-toastr-color-blue-1)!important;
  }
}

.snackbar-warning {
  border-radius: 8px;
  border: var(--aria-toastr-color-yellow-1) 1px solid!important;
  background-color: var(--aria-toastr-color-yellow-2)!important;
  color: var(--aria-toastr-color-yellow-1)!important;
  .mat-mdc-snack-bar-container, .mdc-snackbar__surface, .mdc-snackbar__label, .mdc-button__label, a  {
    background-color: var(--aria-toastr-color-yellow-2)!important;
    color: var(--aria-toastr-color-yellow-1)!important;
    border-radius: 8px!important;
  }
  .toast-title .toast-text {
    color: var(--aria-toastr-color-yellow-1)!important;
  }
}

.snackbar-success {
  border-radius: 8px;
  border: var(--aria-toastr-color-green-1) 1px solid!important;
  background-color: var(--aria-toastr-color-green-2)!important;
  color: var(--aria-toastr-color-green-1)!important;
  .mat-mdc-snack-bar-container, .mdc-snackbar__surface, .mdc-snackbar__label, .mdc-button__label, a  {
    background-color: var(--aria-toastr-color-green-2)!important;
    color: var(--aria-toastr-color-green-1)!important;
    border-radius: 8px!important;
  }
  .toast-title .toast-text {
    color: var(--aria-toastr-color-green-1)!important;
  }
}

.snackbar-error {
  border-radius: 8px;
  border: var(--aria-toastr-color-red-1) 1px solid!important;
  background-color: var(--aria-toastr-color-red-3)!important;
  color: var(--aria-toastr-color-red-2)!important;
  .mat-mdc-snack-bar-container, .mdc-snackbar__surface, .mdc-snackbar__label, .mdc-button__label, a  {
    background-color: var(--aria-toastr-color-red-3)!important;
    color: var(--aria-toastr-color-red-2)!important;
    border-radius: 8px!important;
  }
  .toast-title .toast-text {
    color: var(--aria-toastr-color-red-2)!important;
  }
}

.ellipsis {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: gray;

  @include apply-color($aria-tenant, 'background-color');
}

.DELIVERED {
  background-color: var(--aria-system-color-success);
}

mat-icon {
  @include apply-color($aria-tenant, 'fill', 'svg');
}

.pagination-container {
  background-color: var(--aria-background-color-1) !important;
  border-radius: 0px 0px 8px 8px !important;
  position: sticky;
}

.app-profile {
  border-radius: 50%;
  display: flex;
  width: 36px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  background-color: var(--aria-primary-color-500) !important;
  color: var(--aria-background-color-1) !important;

  &.big {
    height: 75px !important;
    width: 75px !important;
    font-size: 32px;
    font-weight: 600;
    border: solid 5px var(--aria-background-color-1);
  }

  &.min {
    width: 33px !important;
    height: 33px !important;
    font-size: 14px;
    cursor: default;
  }

  &.warn {
    background-color: var(--aria-system-color-warning) !important;
  }
}

mat-card {
  &.aria-card {
    border: var(--aria-black-color-500) 0.5px solid;
    border-radius: 10px!important;
    padding: 24px!important;
    box-shadow: none;
    margin: 0!important;
  }


  &.transparent-card {
    background-color: transparent;
    border: none;
    border-style: none;
    box-shadow: none;
  }
}

.aria-confirmation-panel {
  mat-dialog-container {
    .mat-mdc-dialog-surface {
      border-radius: 20px;
      background: var(--aria-background-color-1);
      padding: 27px 37px;

      .close-icon {
        position: absolute;
        right: 0;
        margin-right: 30px;
        margin-top: -6px;

        mat-icon {
          cursor: pointer;
          height: 28px;
          width: 28px;
          font-size: 28px;
          color: var(--aria-misc-color-blue);
        }
      }

      .confirmation-icon {
        display: flex;
        justify-content: center;

        mat-icon {
          height: 48px;
          width: 48px;
        }
      }

      p {
        color: var(--aria-black-color-600);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin: 32px 0;
      }

      .buttons {
        padding: 0 20px;

        button {
          width: 100%;
        }
      }
    }
  }
}

.panel-loading app-loading {
  height: 100%!important;
}

.pointer {
  cursor: pointer;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: auto;
  height: 100%;
  width: 100%;
  background-color: var(--aria-background-color-1);
}

mat-chip-listbox.inline-chip {
  display: inline-block;
  margin-right: 3px;
}

h6.aria-panel-title {
  @extend .aria-panel-title;
}

.text-right {
  text-align: right;
}

.rotate-0 {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.rotate-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.info-table {
  width: 100%;
  border-collapse: separate;
  margin-top: 12px;
  table-layout: fixed;
  overflow: hidden;

  tr {
    th, td {
      &:first-child {
        width: 140px;
        background-color: var(--aria-primary-color-50);
        padding: 6px 14px 6px 18px;
        color: var(--aria-primary-color-900);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: left;
        vertical-align: middle;
      }

      &:not(:first-child) {
        width: 100%;
        padding-left: 18px;
        border-bottom-width: 2px;
        border-bottom-color: var(--aria-black-color-50);
        border-bottom-style: solid;
        color: var(--aria-black-color-700);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        vertical-align: middle;
      }
    }
  }
}

.info-table-h {
  width: 100%;
  border-collapse: separate;
  margin-top: 12px;
  table-layout: fixed;
  overflow: hidden;

  tr {
    th {
      width: 100%;
      // width: 140px;
      background-color: var(--aria-primary-color-50);
      padding: 6px 0px 6px 18px;
      color: var(--aria-primary-color-900);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: left;
      vertical-align: top;
      padding: 7px 18px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    td {
      width: 100%;
      padding: 7px 18px;
      border-bottom-width: 2px;
      border-bottom-color: var(--aria-black-color-50);
      border-bottom-style: solid;
      color: var(--aria-black-color-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: left;
      vertical-align: top;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    
  }
}

.account-list {
  mat-list-item {
      align-items: center;
      height: 52px;
      border-bottom: solid 1px var(--aria-black-color-50);
      padding: 0 14px !important;

      &:first-child {
          border-top: solid 1px var(--aria-black-color-50)
      }

      .mat-mdc-list-item-icon {
          align-self: center;
          margin-right: 11px;
          margin-left: 0;
      }

      .mdc-list-item__content {
          align-self: center;
          color: var(--aria-black-color-700);
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;

          .mdc-list-item__primary-text {
            display: flex;
          }

          .mdc-list-item__secondary-text {
              font-weight: 400;
          }
      }

      .mat-mdc-list-item-meta {
          align-self: center;
          margin-right: -12px;
      }
  }
}

.mdc-evolution-chip-set__chips {
  width: 100%;
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary, 
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary, 
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden!important;
}

mat-chip {
  overflow: hidden;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after, .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
  background: linear-gradient(0deg, rgba(254, 252, 252, 0.60) 0%, rgba(254, 252, 252, 0.60) 100%), var(--aria-semantic-color-green-light-4)!important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after, .mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background-color: var(--aria-misc-color-green)!important;
}

.changelog-card-active {
  mat-expansion-panel {
    background-color: var(--aria-misc-color-notification)!important;
  }
}

json-form:not(.changelog-form) {
  .grid {
    gap: $row-gap $column-gap !important;
  }

  radio-control {
    mat-radio-group {
      .mat-mdc-radio-button {
        &:first-of-type {
          .mdc-radio {
            padding-left: 0;
            padding-right: 0;
          }
        }
    
        &:nth-of-type(2) {
          .mdc-radio {
            padding-left: 19px;
            padding-right: 0;
          }
        }

        .mdc-form-field {
          label {
            padding-left: 5px;
          }
        }
      }
    }
  }
}

.responsive-grid {
  display: grid;
  gap: $row-gap $column-gap;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  .width-half {
    grid-column: span 1;
  }

  .width-full {
    grid-column: 1 / -1;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;

    .width-half {
      grid-column: 1 / -1;
    }
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
@use '@angular/material' as mat;
@import '@angular/material/theming';
@import 'mixins';
@include mat-core();

$aria-warn: (
  50 : #fdebeb,
  100 : #f9cccc,
  200 : #f6abab,
  300 : #f28989,
  400 : #ef6f6f,
  500 : #ec5656,
  600 : #ea4f4f,
  700 : #e74545,
  800 : #e43c3c,
  900 : #df2b2b,
  A100 : #ffffff,
  A200 : #ffebeb,
  A400 : #ffb8b8,
  A700 : #ff9f9f,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$aria-accent: (
  50 : #E3F5FA,
  100 : #B8E4F4,
  200 : #8CD3EC,
  300 : #68C2E4,
  400 : #54B5DF,
  500 : #48A8DA,
  600 : #419ACC,
  700 : #3988B9,
  800 : #3577A5,
  900 : #2A5882,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$aria-primary: (
  50 : #E4F7EC,
  100 : #BEEAD0,
  200 : #93DDB2,
  300 : #60D093,
  400 : #00BA64,
  500 : #2DC57C,
  600 : #00AA59,
  700 : #00984D,
  800 : #008741,
  900 : #00672C,
  A100 : #bdfff0,
  A200 : #8affe4,
  A400 : #57ffd9,
  A700 : #3dffd3,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$aria-black: (
  50: #F7F7F7,
  100: #EEEEEE,
  200: #E2E2E2,
  300: #D0D0D0,
  400: #ABABAB,
  500: #8A8A8A,
  600: #636363,
  700: #505050,
  800: #323232,
  900: #000000,
);

// Semantic colors
$aria-semantic: (
  'red': map-get($aria-warn, 500),
  red-dark: #742A2A,
  red-light: #FAD5D5,
  'orange': #EC8E4B,
  orange-dark: #744525,
  orange-light: #FAE3D2,
  'yellow': #F8C953,
  yellow-dark: #7A6329,
  yellow-light: #FDF1D4,
  'green': #51D36E,
  green-dark: #317F42,
  green-light: #B9EDC5,
  green-light-2: #81DCB0,
  green-light-3: #E3F1EA,
  green-light-4: #2BA168,
  green-light-5: #FEFCFC99,
  green-light-6: #00B8A2,
  green-light-7: #E4F2F0,
  green-light-8: #13543A,
  green-light-9: #F6FFFE,
  green-light-10: #E9FFEA,
  'blue': #459FE0,
  blue-dark: #224E6E,
  blue-light: map-get($aria-accent, 900),
  'purple': #A067E8,
  purple-dark: #4E3271,
  purple-light: #E7D9F9,
  'pink': #E74DBC,
  pink-dark: #71265C,
  pink-dark-2: #AA279D,
  pink-light: #F9D3EE,
  pink-light-2: #FDEAFB,
  black-1: #223345,
  gray-1: #5E5D5D,
  gray-2: #EDEDED,
);

$aria-system: (
  primary: map-get($aria-primary, 500),
  accent: map-get($aria-accent, 500),
  warning: map-get($aria-warn, 500),
  success: #43A047,
  alert: map-get($aria-semantic, 'yellow'),
);

// Background colors
$aria-background: (
  1: #FFFFFF,
  2: #F6F8FC,
);

// Toastr colors
$aria-toastr: (
  red-1: map-get($aria-warn, 500),
  red-2: #F44336,
  red-3: #FFEBEE,
  yellow-1: #FF9800,
  yellow-2: #FFF3E0,
  green-1: map-get($aria-system, success),
  green-2: #E8F5E9,
  blue-1: #2196F3,
  blue-2: #E3F2FD,
);

// Misc colors
$aria-misc: (
  sky-blue: #EFF8FF,
  'green': #017466,
  green-with-opacity: #81dcb04d,
  red-4: #FFC5C5,
  red-5: #FDEBEA,
  red-6: #AA273C,
  yellow-1: #FFEDCA,
  yellow-2: #C78B1A,
  brown-1: #FFE2C8,
  brown-2: #AA6627,
  'blue': map-get($aria-accent, 900),
  blue-2: #EBF0FE,
  blue-3: #174F9A,
  button-microsoft: #2F2F2F,
  notification: #E4F7EC,
);

$aria-tenant: (
  AGORA: #02B1FF,
  AGORA_CLUB: #F2007C,
  AGORA_PAY: #00CAFF,
  AGORA_SHOP: #FF6000,
  AGORA_AHORRA: #001FFE,
  MKR: #FFE500,
  TPSA: #FF4949,
  PVEA: #FF4949,
  HPSA: #F59237,
  RPLAZA: #7B61FF,
  SHPSTAR: #0970CC,
  VIV: #168D7F,
  JOKR: #063F71,
  MERKAO: #3534BB
);

// Referencias de Colores
:root {
  @include generate-color-variables('primary', $aria-primary);
  @include generate-color-variables('accent', $aria-accent);
  @include generate-color-variables('warn', $aria-warn);
  @include generate-color-variables('black', $aria-black);
  @include generate-color-variables('semantic', $aria-semantic);
  @include generate-color-variables('system', $aria-system);
  @include generate-color-variables('background', $aria-background);
  @include generate-color-variables('toastr', $aria-toastr);
  @include generate-color-variables('misc', $aria-misc);
  @include generate-color-variables('tenant', $aria-tenant);
}

/* Typography */
$headline1: mat.define-typography-level(32px, 24px, 600);
$headline2: mat.define-typography-level(28px, 24px, 600);
$headline3: mat.define-typography-level(20px, 24px, 500);
$headline4: mat.define-typography-level(18px, 24px, 500);
$headline5: mat.define-typography-level(16px, 24px, 500); // H1
$headline6: mat.define-typography-level(16px, 24px, 500); // H2
$subtitle1: mat.define-typography-level(16px, 24px, 400); // H3
$subtitle2: mat.define-typography-level(14px, 24px, 500); // H4
$body1: mat.define-typography-level(15px, 24px, 400); // Base body text
$body2: mat.define-typography-level(14px, 24px, 400);
$body3: mat.define-typography-level(13px, 24px, 400);
$overline: mat.define-typography-level(11px, 24px, 400);
$caption: mat.define-typography-level(12px, 24px, 400);
$button: mat.define-typography-level(15px, 24px, 400);

// Define the typography config.
$aria-typography: mat.define-typography-config(
  $font-family: 'Barlow',
  $headline-1: $headline1,
  $headline-2: $headline2,
  $headline-3: $headline3,
  $headline-4: $headline4,
  $headline-5: $headline5,
  $headline-6: $headline6,
  $body-1: $body1,
  $body-2: $body2,
  $subtitle-1: $subtitle1,
  $subtitle-2: $subtitle2,
  $button: $button,
  $caption: $caption,
  $overline: $overline
);

$aria-web-primary: mat.define-palette($aria-primary);
$aria-web-accent: mat.define-palette($aria-accent, A200, A100, A400);
$aria-web-warn: mat.define-palette($aria-warn);

$aria-web-theme: mat.define-light-theme((
  color: (
    primary: $aria-web-primary,
    accent: $aria-web-accent,
    warn: $aria-web-warn,
  ),
  typography: $aria-typography,
  density: -2,
));

@include mat.all-component-themes($aria-web-theme);
@include mat.typography-hierarchy($aria-typography);

$scroll-color: var(--aria-black-color-300);
$scroll-background: var(--aria-background-color-1);
$button-height: 48px;
$grid-gap: 12px;
$row-gap: 19px;
$column-gap: 12px;

// button default styles
.mdc-button {

  &.mat-mdc-button-base {
    height: $button-height;
  }

  &.mat-primary {
    &.mdc-button--raised, &.mdc-button--unelevated {
      color: var(--aria-background-color-1) !important;

      &:hover {
        background-color: var(--aria-primary-color-200) !important;
      }

      &:disabled {
        background-color: var(--aria-primary-color-300) !important;
      }

      &:active, &:focus {
        background-color: var(--aria-primary-color-200) !important;
      }
    }

    &:disabled {
      color: var(--aria-primary-color-200);
    }

    &.mat-mdc-raised-button {
      &:disabled {
        background-color: #2ba16899;
      }
    }

    &.mat-mdc-outlined-button {
      &:not(:disabled) {
        border-color: var(--aria-system-color-primary);
      }
    }
  }

  &.mat-warn {
    &.mat-mdc-outlined-button {
      border-color: var(--aria-misc-color-red-7);

      &:disabled {
        border-color: var(--aria-misc-color-red-4);
        color: var(--aria-misc-color-red-4);
      }
    }
  }
}

// form default styles
.mat-mdc-text-field-wrapper {
  background-color: var(--aria-background-color-1)!important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--invalid) .mdc-notched-outline__leading, 
.mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--invalid) .mdc-notched-outline__notch, 
.mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--invalid) .mdc-notched-outline__trailing {
  border-color: var(--aria-black-color-100);
}

.mdc-text-field--outlined:not(.mdc-text-field--focused):hover .mdc-notched-outline__leading, 
.mdc-text-field--outlined:not(.mdc-text-field--focused):hover .mdc-notched-outline__notch, 
.mdc-text-field--outlined:not(.mdc-text-field--focused):hover .mdc-notched-outline__trailing {
  border-color: var(--aria-black-color-100)!important;
}

mat-select {
  background-color: inherit;
  border-radius: 5px;
  display: inline-block;
  width: 100%;
  outline: none;
  font-size: var(--mdc-typography-subtitle1-font-size, 16px) !important;

  .mat-mdc-select-trigger {
    .mat-mdc-select-value {
      color: var(--aria-accent-color-800);

      .mat-mdc-select-placeholder {
        color: inherit;
      }
    }

    mat-select-trigger.has-plus {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .display-label {
        //flex: 1;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      
      .display-plus {
        text-align: right;
        //width: 10%;
        margin-left: 3px;
      }
    }
  }

  .mat-mdc-select-arrow-wrapper {
    .mat-mdc-select-arrow {
      display: none;
    }

    &::after {
      content: "expand_more";
      display: inline-block;
      font-family: 'Material Icons';
      font-size: 16px;
      margin-right: -3px;
      padding-left: 5px;
      color: var(--aria-accent-color-900);
    }
  }

  .mdc-line-ripple {
    display: none;
  }

  &.mat-mdc-select-disabled {
    .mat-mdc-select-trigger {
      .mat-mdc-select-value {
        color: var(--aria-black-color-600);
      }
    }

    .mat-mdc-select-arrow-wrapper {
      &::after {
        color: var(--aria-black-color-600);
      }
    }
  }
}

%mat-form-field-icon-prefix-styles {
  .mat-mdc-text-field-wrapper {
    padding-left: 0;
    --mat-mdc-form-field-label-offset-x: 20px;
  }

  .mat-mdc-floating-label {
    transform: var(--mat-mdc-form-field-label-transform, translateY(-50%) translateX(calc(1 * (var(--mat-mdc-form-field-label-offset-x, 0px))))) !important;
  }

  .mat-mdc-form-field-icon-prefix, [dir=rtl] .mat-mdc-form-field-icon-suffix {
    color: var(--aria-misc-color-blue);
    padding: 0;

    mat-icon {
      height: 20px;
      width: 20px;
      font-size: 20px;
      line-height: 20px;
      margin-right: 0;
    }
  }

  &.mat-focused {
    .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
      color: var(--aria-system-color-primary);
    }
  }

  &.mat-form-field-invalid {
    .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
      color: var(--aria-warn-color-500);
    }
  }
}

mat-pseudo-checkbox {
  color: var(--aria-black-color-200) !important;
  height: 16px !important;
  width: 16px !important;
  border-radius: 2.5px !important;
}

.mat-mdc-menu-panel {
  .mat-mdc-menu-item:not(:last-child) {
    border-bottom: 1px solid var(--aria-semantic-color-green-light-3)!important;
  }
  
  span.mdc-list-item__primary-text {
    color: var(--aria-misc-color-blue) !important;
    width: 100%!important;
  }
  
}

.mat-mdc-select-panel {
  padding: 0px !important;

  mat-option {

    &.mat-mdc-option {
      border-bottom: 1px solid var(--aria-semantic-color-green-light-3)!important;

      span.mdc-list-item__primary-text {
        color: var(--aria-misc-color-blue) !important;
        width: 100%!important;
      }

      .mat-pseudo-checkbox-checked {
        display: none;
      }

      &.mat-mdc-option-multiple {
        .mat-pseudo-checkbox-checked {
          display: inline-block;
          background-color: var(--aria-system-color-primary) !important;
        }
      }

      &.mat-option-button-clean-select {
        .mat-pseudo-checkbox {
          display: none !important;
        }
      }

      &:not(.mat-mdc-option-multiple).mat-mdc-option-active {
        color: var(--aria-misc-color-blue)!important;
        background-color: var(--aria-semantic-color-green-light-3)!important;
      }

      &.mat-option-search-box {
        padding: 3px!important;
        opacity: 1!important;
        border: none!important;
        min-width: 200px;

        .mat-pseudo-checkbox {
          display: none!important;
        }

        .mdc-list-item__primary-text {
          width: 100%!important;

          mat-form-field {
            .mat-mdc-text-field-wrapper {
              background-color: var(--aria-background-color-2)!important;
            }

            mat-icon {
              color: var(--aria-black-color-800);
            }

            &.mat-mdc-form-field-has-icon-prefix {
              @extend %mat-form-field-icon-prefix-styles;
            }
          }
        }
      }

      &.mat-option-button-clean-select {
        .mat-mdc-button {
          display: flex;
          margin-left: auto;
        }
      }

      mat-icon {
        &.icon-xs {
          height: 8px;
          width: 8px;
          line-height: 4px;
          margin-right: 7px;
        }
      }
    }
  }
}

mat-form-field {
  &.mat-form-field-appearance-outline {
    &.search-box {
      width: 100%;

      mat-icon {
        color: var(--aria-misc-color-blue)!important;
      }

      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }

    &.borderless {
      .mat-mdc-form-field-flex {
        .mdc-notched-outline {
          .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
            border: transparent;
          }
        }
      }
    }

    &.selected-value:not(.mat-form-field-invalid) {
      .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
        border-color: var(--aria-primary-color-500);
      }

      .mdc-floating-label {
        color: var(--aria-primary-color-500) !important;
      }

      mat-select {
        .mat-mdc-select-trigger {
          .mat-mdc-select-value {
            color: var(--aria-primary-color-500) !important;
          }
        }

        .mat-mdc-select-arrow-wrapper::after {
          color: var(--aria-primary-color-500) !important;
        }
      }

      .mdc-text-field__input, .mat-date-range-input-separator {
        color: var(--aria-primary-color-500) !important;
      }

      &.mat-mdc-form-field-has-icon-prefix {
        .mat-mdc-form-field-icon-prefix {
          color: var(--aria-primary-color-500) !important;
        }
      }
    }
  }

  &.no-icon-prefix-styles {
    .mat-mdc-form-field-icon-prefix {
      padding: 0;
    }
  }

  .mat-mdc-form-field-error-wrapper {
    font-family: 'Barlow';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 3px 12px;
  }

  .mdc-text-field__input {
    font-size: var(--mdc-typography-subtitle1-font-size, 16px) !important;
    color: var(--aria-black-color-800) !important;
  }

  &.mat-form-field-disabled {
    .mat-mdc-floating-label {
      color: var(--aria-black-color-600) !important;
    }

    .mat-mdc-text-field-wrapper {
      background-color: var(--aria-black-color-50) !important;
    }
  }

  &.mat-form-field-invalid {
    .mdc-text-field__input {
      color: var(--aria-misc-color-blue) !important;
    }

    .mat-mdc-floating-label {
      &.mdc-floating-label--float-above {
        color: var(--aria-semantic-color-red) !important;
      }
    }
  }

  &:not(.selected-value) {
      .mdc-text-field:not(
      .mdc-text-field--disabled,
      .mdc-text-field--invalid,
      .mdc-text-field--focused) .mdc-floating-label{
      color: var(--aria-accent-color-800)!important;
    }
  }
  
  &.mat-mdc-form-field-has-icon-prefix {
    @extend %mat-form-field-icon-prefix-styles;
  }

  &.mat-mdc-form-field-type-mat-date-range-input {
    .mat-mdc-form-field-infix {
      width: 100%;
    }

    mat-datepicker-toggle {
      mat-icon {
        font-size: 16px;
        height: 16px;
        width: 16px;
        line-height: 13px;
      }
    }
  }
}

.aria-filters {
  mat-form-field {
    .mdc-text-field__input, input::placeholder {
      color: var(--aria-misc-color-blue) !important;
    }
  }

  &.no-input-errors {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

mat-chip {
  border-radius: 10px!important;
  padding: 5px 10px!important;
  width: fit-content!important;

  .mdc-evolution-chip__action--primary {
    padding: 0px!important;
  }

  .mdc-evolution-chip__text-label {
    text-align: center!important;
    font-size: 11.5px!important;
    line-height: 14px!important;
  }

  .mdc-evolution-chip__cell {
    overflow: hidden!important;
  }

  &.mat-chip-blue {
    border: 1px solid var(--aria-misc-color-blue)!important;
    background: var(--aria-misc-color-sky-blue)!important;

    .mdc-evolution-chip__text-label {
      color: var(--aria-misc-color-blue)!important;
    }
  }

  &.mat-chip-green {
    border: 1px solid var(--aria-semantic-color-green-light-6)!important;
    background: var(--aria-semantic-color-green-light-9)!important;

    .mdc-evolution-chip__text-label {
      color: var(--aria-semantic-color-green-light-6)!important;
    }
  }

  &.mat-chip-solid-green {
    background: var(--aria-primary-color-800)!important;

    .mdc-evolution-chip__text-label {
      color: var(--aria-background-color-1)!important;
    }
  }

  &.mat-chip-strech-green {
    background: var(--aria-misc-color-green) !important;
    padding: 1px 4px !important;
    height: auto !important;
    width: -moz-fit-content !important;
    width: fit-content !important;

    .mdc-evolution-chip__text-label {
      color: var(--aria-background-color-1) !important;
      font-size: 8px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }
  }
}

.mat-mdc-standard-chip {
  z-index: 0;
  width: auto !important; // 99px
  height: 20px !important;
}

mat-slide-toggle {
  &.mat-primary {
    .mdc-switch {
      width: 43px;

      &:enabled {
        .mdc-switch__track {
          border-radius: 14px;

          &::before {          
            background: linear-gradient(0deg, rgba(254, 252, 252, 0.60) 0%, rgba(254, 252, 252, 0.60) 100%), var(--aria-semantic-color-green-light-4) !important;
          }
  
          &::after {
            background: linear-gradient(0deg, rgba(254, 252, 252, 0.60) 0%, rgba(254, 252, 252, 0.60) 100%), var(--aria-semantic-color-green-light-4) !important;
          }
        }
      }

      &:disabled {
        .mdc-switch__track {
          opacity: 1;

          &::before {
            background: var(--aria-black-color-200) !important;
          }

          &::after {
            background: var(--aria-semantic-color-green-light-3);
          }
        }
      }

      .mdc-switch__handle {
        .mdc-switch__icons {
          display: none;
        }
      }

      &.mdc-switch--selected {
        &:enabled {
          .mdc-switch__handle::after {
            background: var(--aria-misc-color-green) !important;
          }
        }

        &:disabled {
          .mdc-switch__handle::after {
            background: var(--aria-semantic-color-green-light-4) !important;
          }
        }
      }

      &.mdc-switch--unselected {
        &:enabled {
          .mdc-switch__handle::after {
            background: var(--aria-semantic-color-green-light-3) !important;
          }
        }

        &:disabled {
          .mdc-switch__handle::after {
            background: var(--aria-black-color-400) !important;
            opacity: 1;
          }
        }
      }
    }
  }
}

/* SCROLL OVERRIDES */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: $scroll-background;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: $scroll-background;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $scroll-color;
}

/* TOASTER STYLES */
.tracking-toastr-information {
  max-width: 760px;

  .swal2-popup.swal2-toast .swal2-html-container {
    margin: 0em 0.8em;
  }

  .toast-info {
    border: var(--aria-toastr-color-blue-1) 1px solid !important;
    border-radius: 8px !important;
    background-color: var(--aria-toastr-color-blue-2) !important;
    padding: 16px !important;

    nb-icon, mat-icon {
      color: var(--aria-toastr-color-blue-1) !important;
    }

    .message-title {
      color: var(--aria-toastr-color-blue-1) !important;
      font-family: 'Barlow';
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }

    .message-body {
      color: var(--aria-toastr-color-blue-1) !important;
      font-family: 'Barlow';
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }
  }

  .toast-succcess {
    border: var(--aria-toastr-color-green-1) 1px solid !important;
    border-radius: 8px !important;
    background-color: var(--aria-toastr-color-green-2) !important;
    padding: 16px;

    nb-icon, mat-icon {
      color: var(--aria-toastr-color-green-1) !important;
    }

    .message-title {
      color: var(--aria-toastr-color-green-1) !important;
      font-family: 'Barlow';
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }

    .message-body {
      color: var(--aria-toastr-color-green-1) !important;
      font-family: 'Barlow';
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }
  }

  .toast-warning {
    border: var(--aria-toastr-color-yellow-1) 1px solid !important;
    border-radius: 8px !important;
    background-color: var(--aria-toastr-color-yellow-2) !important;
    padding: 16px !important;

    nb-icon, mat-icon {
      color: var(--aria-toastr-color-yellow-1) !important;
    }

    .message-title {
      color: var(--aria-toastr-color-yellow-1) !important;
      font-family: 'Barlow';
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }

    .message-body {
      color: var(--aria-toastr-color-yellow-1) !important;
      font-family: 'Barlow';
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }
  }

  .toast-error {
    border: var(--aria-toastr-color-red-1) 1px solid !important;
    border-radius: 8px !important;
    background-color: var(--aria-toastr-color-red-3) !important;
    padding: 16px;

    nb-icon, mat-icon {
      color: var(--aria-toastr-color-red-2) !important;
    }

    .message-title {
      color: var(--aria-toastr-color-red-2) !important;
      font-family: 'Barlow';
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }

    .message-body {
      color: var(--aria-toastr-color-red-2) !important;
      font-family: 'Barlow';
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }
  }
}

.tracking-toastr-confirmation {
  .message-body {
    color: var(--aria-misc-color-blue) !important;
    font-family: 'Barlow' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    text-align: center !important;
    margin: 0px !important;
  }

  .swal2-actions button {
    margin: 0px 10px !important;
  }

  .swal2-close {
    color: var(--aria-misc-color-blue) !important;
  }

  .swal2-close:hover {
    color: var(--aria-misc-color-blue) !important;
  }

  .swal2-popup {
    padding: 15px 15px 35px 15px;
  }
}



//SPAN CHIP TICKET STATUS
.mat-chip-ticket-status-1, .mat-chip-ticket-status-9 {
  border-radius: 4px!important;
  width: 80%!important;
  background-color: var(--aria-misc-color-red-5)!important;
  .mdc-evolution-chip__action--primary {
    width: 100%!important;
  }
  .mdc-evolution-chip__text-label {
    color: var(--aria-misc-color-red-6)!important;
    text-align: center!important;
    font-size: 14px!important;
    font-family: 'Barlow'!important;
  }
}

.mat-chip-ticket-status-2, .mat-chip-ticket-status-10 {
  border-radius: 4px!important;
  width: 80%!important;
  background-color: var(--aria-misc-color-yellow-1)!important;
  .mdc-evolution-chip__action--primary {
    width: 100%!important;
  }
  .mdc-evolution-chip__text-label {
    color: var(--aria-misc-color-yellow-2)!important;
    text-align: center!important;
    font-size: 14px!important;
    font-family: 'Barlow'!important;
  }
}


.mat-chip-ticket-status-3 {
  border-radius: 4px!important;
  width: 80%!important;
  background-color: var(--aria-misc-color-blue-2)!important;
  .mdc-evolution-chip__action--primary {
    width: 100%!important;
  }
  .mdc-evolution-chip__text-label {
    color: var(--aria-misc-color-blue-3)!important;
    text-align: center!important;
    font-size: 14px!important;
    font-family: 'Barlow'!important;
  }
}


.mat-chip-ticket-status-4 {
  border-radius: 4px!important;
  width: 80%!important;
  background-color: var(--aria-semantic-color-green-light-7)!important;
  .mdc-evolution-chip__action--primary {
    width: 100%!important;
  }
  .mdc-evolution-chip__text-label {
    color: var(--aria-semantic-color-green-light-8)!important;
    text-align: center!important;
    font-size: 14px!important;
    font-family: 'Barlow'!important;
  }
}


.mat-chip-ticket-status-5 {
  border-radius: 4px!important;
  width: 80%!important;
  background-color: var(--aria-misc-color-brown-1)!important;
  .mdc-evolution-chip__action--primary {
    width: 100%!important;
  }
  .mdc-evolution-chip__text-label {
    color: var(--aria-misc-color-brown-2)!important;
    text-align: center!important;
    font-size: 14px!important;
    font-family: 'Barlow'!important;
  }
}

.mat-chip-ticket-status-6, .mat-chip-ticket-status-11 {
  border-radius: 4px!important;
  width: 80%!important;
  background-color: var(--aria-semantic-color-green-light-10)!important;
  .mdc-evolution-chip__action--primary {
    width: 100%!important;
  }
  .mdc-evolution-chip__text-label {
    color: var(--aria-system-color-success)!important;
    text-align: center!important;
    font-size: 14px!important;
    font-family: 'Barlow'!important;
  }
}

.mat-chip-ticket-status-12 {
  border-radius: 4px!important;
  width: 80%!important;
  background-color: var(--aria-semantic-color-gray-2)!important;
  .mdc-evolution-chip__action--primary {
    width: 100%!important;
  }
  .mdc-evolution-chip__text-label {
    color: var(--aria-semantic-color-gray-1)!important;
    text-align: center!important;
    font-size: 14px!important;
    font-family: 'Barlow'!important;
  }
}

mat-datepicker-content {
  .mat-calendar {
    height: 370px !important;
  }
}

.mat-mdc-tab-nav-bar {
  .mdc-tab__text-label {
    color: var(--aria-misc-color-blue);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  &.vertical-nav-bar {
    display: block;

    .mat-mdc-tab-link-container {
      display: block;

      .mat-mdc-tab-links {
        flex-direction: column;

        .mat-mdc-tab-link {
          display: block;
          padding: 15px 14px 15px 13px;
          height: auto;
          min-height: 90px;

          .mdc-tab__content {
            display: block;

            .mdc-tab__text-label {
              display: block;
              text-align: left;
              text-wrap: wrap;

              .tab-title {
                display: block;
                color: var(--aria-primary-color-900);
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
              }

              .tab-description {
                display: block;
                color: var(--aria-black-color-700);
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
              }
            }
          }

          &.mdc-tab-indicator--active {
            background-color: var(--aria-primary-color-50);

            .mdc-tab-indicator__content--underline {
              display: none;
            }
          }
        }
      }
    }
  }
}